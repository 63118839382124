:root {
  --gap: clamp(1rem, 5vw, 3rem);
  --col: 15rem;
  --accent: #{op.$blue-7};
  --middleground: #{op.$gray-6};

  --component-shadow: #{op.shadow(2)};
  --component-inner-shadow: #{op.shadow('inner-1')};
  color-scheme: light;

  // https://web.dev/accent-color/
  accent-color: var(--accent);

  // https://enzedonline.com/en/tech-blog/create-responsive-font-sizes-based-on-the-viewport/
  --font-size-body: font-size: clamp(1rem, 0.7859rem + 0.9014vw, 1.8rem);
}
