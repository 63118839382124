html,
body {
  height: 100%;
}

body {
  display: grid;
  gap: var(--gap);
  grid-template-rows: auto 1fr auto;

  > main {
    @include grid;

    gap: 0;

    & > * {
      grid-column: feature;
    }
  }

  > header,
  > footer {
    @include grid;

    gap: 0;

    & > * {
      grid-column: full;
    }
  }

  > footer {
    position: sticky;
    bottom: 0;
    background-color: var(--bs-body-bg);
    box-shadow: var(--component-shadow);

    nav {
      padding: calc(var(--gap) * 0.5) calc(var(--gap) * 0.1);
      margin-inline: calc(var(--gap) * 0.1);
      gap: calc(var(--gap) * 0.1);

      .nav-link {
        box-shadow: var(--component-inner-shadow);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: calc(var(--gap) * 0.2);
        padding: calc(var(--gap) * 0.3);

        .icon {
          display: block;
        }
      }
    }
  }
}
