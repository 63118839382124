@mixin grid() {
  display: flex;
  flex-flow: column wrap;
  gap: var(--gap);
  align-content: flex-start;

  @supports (grid-area: auto) {
    display: grid;

    // https://ryanmulligan.dev/blog/layout-breakouts/
    // Usage:
    // .content > * {
    //   grid-column: content;
    // }
    // .popout {
    //   grid-column: popout;
    // }
    // .feature {
    //   grid-column: feature;
    // }
    // .full {
    //   grid-column: full;
    // }

    --gap: clamp(1rem, 6vw, 3rem);
    --full: minmax(var(--gap), 1fr);
    --content: min(80ch, 100% - var(--gap) * 2);
    --popout: minmax(0, 2rem);
    --feature: minmax(0, 5rem);

    grid-template-columns:
      [full-start] var(--full)
      [feature-start] var(--feature)
      [popout-start] var(--popout)
      [content-start] var(--content) [content-end]
      var(--popout) [popout-end]
      var(--feature) [feature-end]
      var(--full) [full-end];
  }
}

@mixin gridAuto() {
  flex-flow: row wrap;

  @supports (grid-area: auto) {
    grid-template-columns: repeat(auto-fill, minmax(var(--col), 1fr));
  }
}

@mixin grid-reset {
  grid-column: -1/1;
}

.grid {
  @include grid;
}

.grid-auto {
  @include gridAuto;
}

.grid-reset {
  @include grid-reset;
}
