.list-group {
  @extend .list-group-flush;
  margin-block-end: 1rem;

  li {
    gap: 1rem;

    > div:first-child {
      width: 100%;
      display: block;
      position: relative;
    }
    > div:last-child {
      align-self: center;
    }
  }

  h2 a {
    text-decoration: none;
    color: #{op.$gray-7};
  }
}
