/* icons */
body > svg {
  display: none;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  width: #{op.$size-3}; // 1rem
  height: #{op.$size-3};
  stroke: currentColor;
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  // margin-top: -0.25rem;

  // .is-active & {
  //   fill: var(--accent);
  // }

  .disabled a & {
    stroke: var(--middleground);
  }

  // Wagtail Userbar icon nicht geändert
  &.wagtail-action-icon {
    fill: unset;
    stroke: transparent;
  }

  // Sizes
  &--smmd {
    width: #{op.$size-4}; // 1.25rem
    height: #{op.$size-4}; // 1.25rem
  }

  &--md {
    width: #{op.$size-5}; // 1.5rem
    height: #{op.$size-5}; // 1.5rem
  }

  &--lg {
    width: #{op.$size-7}; // 2rem
    height: #{op.$size-7}; // 2rem
  }

  &--xl {
    width: #{op.$size-8}; // 3rem
    height: #{op.$size-8}; // 3rem
  }
}
